<template>
	<v-navigation-drawer :value="opened" fixed app color="sidebarBackground" @input="toogle">
		<div class="d-flex align-center px-10 py-2">
			<div class="flex-shrink-1">
				<v-btn plain fab active-class="" :to="{ name: 'Landing' }">
					<v-avatar size="48" class="mr-4">
						<v-img src="@/assets/images/icon/logo-oversos-second-1x.png" />
					</v-avatar>
				</v-btn>
			</div>
			<span class="text-h5" style="font-family: Tahoma">OverSOS</span>
		</div>
		<v-list v-if="setupInfo.verified.value" nav dense rounded class="pl-8 pr-5">
			<v-list-item :to="{ name: 'NewsfeedPosts' }" v-if="setupInfo.studies.verifiedStudies && $vuetify.breakpoint.lgAndUp">
				<v-list-item-icon>
					<v-icon>mdi-rss</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('nav.newsfeed') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item :to="{ name: 'Sponsors' }" v-if="setupInfo.superAccess">
				<v-list-item-icon>
					<v-icon>mdi-heart-multiple</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('nav.sponsors') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item href="https://blog.oversos.com" target="_blank">
				<v-list-item-icon>
					<v-icon>mdi-typewriter</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Blog</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<template v-if="setupInfo.studies.verifiedStudies">
				<v-subheader>OverSOS Network</v-subheader>

				<v-list-item :to="{ name: 'Companies' }">
					<v-list-item-icon>
						<v-icon>mdi-domain</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.companies') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Attendances' }">
					<v-list-item-icon>
						<v-icon>mdi-calendar</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('events.schedule') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Applications' }" v-if="$vuetify.breakpoint.lgAndUp">
					<v-list-item-icon>
						<v-icon>mdi-briefcase</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.jobs') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Messages' }" v-if="$vuetify.breakpoint.lgAndUp">
					<v-list-item-icon>
						<v-icon>mdi-forum</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.messages') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>

			<template v-if="setupInfo.studies.verifiedOpenedStudies && setupInfo.accountType.student.value">
				<v-subheader>SOS</v-subheader>

				<v-list-item :to="{ name: 'Exams' }">
					<v-list-item-icon>
						<v-icon>mdi-text-box-check-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.exams') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Courses' }">
					<v-list-item-icon>
						<v-icon>mdi-school</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ $t('nav.courses') }}</v-list-item-title>
					<v-list-item-action class="ma-0">
						<v-btn text icon small :to="{ name: 'CourseAdd' }">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>

				<v-list-item-group value="true" class="pl-3" :style="{ overflowY: 'auto', maxHeight: coursesListMaxHeight }">
					<v-list-item
						v-for="(course, i) in courses"
						:key="i"
						:to="{ name: 'Course', params: { id: course.id } }"
						dense
						class="pl-12"
						style="min-height: 25px"
					>
						<v-list-item-content>
							<v-list-item-title>{{ course.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</template>
		</v-list>

		<v-footer padless fixed absolute color="sidebarBackground">
			<template v-if="$vuetify.breakpoint.lgAndUp">
				<div class="d-flex align-center px-3 py-3" style="width: 100%">
					<div class="flex-grow-0">
						<v-avatar size="48" class="mr-4">
							<v-img :src="currentUser.imgURL" />
						</v-avatar>
					</div>
					<div class="mr-2 d-flex flex-column flex-grow-1">
						<strong>{{ currentUser.name.first }}</strong>
						<small>{{ currentUser.name.last }}</small>
					</div>
					<div class="flex-grow-0 mx-1">
						<SidebarNotifications />
					</div>
					<div class="flex-grow-0">
						<SidebarMenu />
					</div>
				</div>
			</template>
			<template v-else>
				<v-row justify="center" no-gutters>
					<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Contact' }">
						{{ $t('nav.contactUs') }}
					</v-btn>
					<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'FAQ' }">
						{{ $t('nav.faq') }}
					</v-btn>
					<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Team' }">
						{{ $t('nav.team') }}
					</v-btn>
					<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Policies' }">
						{{ $t('policies.title') }}
					</v-btn>
				</v-row>
				<v-row justify="center" no-gutters>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://instagram.com/oversos.official" target="_blank">
							<v-icon>mdi-instagram</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://facebook.com/oversos" target="_blank">
							<v-icon>mdi-facebook</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://twitter.com/oversosofficial" target="_blank">
							<v-icon>mdi-twitter</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://linkedin.com/company/oversos" target="_blank">
							<v-icon>mdi-linkedin</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row justify="center" no-gutters>
					<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
				</v-row>
			</template>
		</v-footer>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Sidebar',
	data() {
		return {
			selectedItem: null,
		}
	},
	components: {
		SidebarNotifications: () => import('@/components/main/SidebarNotifications.vue'),
		SidebarMenu: () => import('@/components/main/SidebarMenu.vue'),
	},
	computed: {
		coursesListMaxHeight() {
			if (this.$vuetify.breakpoint.lgAndUp) {
				return this.$vuetify.breakpoint.height ? `${this.$vuetify.breakpoint.height - 610}px` : '160px'
			} else {
				return this.$vuetify.breakpoint.height ? `${this.$vuetify.breakpoint.height - 530}px` : '80px'
			}
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
			currentUser: 'user/currentUser',
			courses: 'courses/courses',
			opened: 'sidebar/opened',
		}),
	},
	beforeMount() {
		if (this.$vuetify.breakpoint.mdAndDown & this.opened) {
			this.close()
		} else if (this.$vuetify.breakpoint.lgAndUp & !this.opened) {
			this.open()
		}
	},
	methods: {
		...mapActions('sidebar', ['open', 'close', 'toogle']),
	},
}
</script>

<style scoped>
.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
	margin-bottom: 1px !important;
}
</style>
